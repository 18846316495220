import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovMakesResponse = operations['getMakes']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryParams = operations['getMakes']['parameters']['query']
type QueryOpts = UseQueryOptions<unknown, unknown, LovMakesResponse>

export async function fetchLovMakes(
  searchParams: QueryParams = {},
  headers?: HeadersInit
) {
  const res = await apiFetcher<LovMakesResponse>(URLS.lovMakes, {
    searchParams,
    headers
  })

  return res.json
}

export function getMakeDetailQueryParams(): QueryParams {
  return { active: true }
}

export function useLovMakesQuery(searchParams?: QueryParams, opts?: QueryOpts) {
  return useQuery(
    QUERY_KEYS.lovMakes(searchParams),
    async () => fetchLovMakes(searchParams),
    { ...lovQueryOptions, ...opts }
  )
}

export function useMakeList(args?: {
  search?: string
  maxFeaturedCount?: number
}) {
  const { maxFeaturedCount, search } = args || {}
  const { data, ...rest } = useLovMakesQuery({ active: true })

  const carMakes = useMemo(() => {
    const makes = data?.content || []
    return makes
  }, [data])

  const featuredMakes = useMemo(
    () =>
      carMakes
        .filter(make => make.popularity)
        .sort((a, b) =>
          a.popularity && b.popularity ? a.popularity - b.popularity : 0
        )
        .slice(0, maxFeaturedCount),
    [carMakes, maxFeaturedCount]
  )

  const filteredCarMakes = useMemo(() => {
    if (!search) {
      return carMakes
    }

    const optSearch = search.toLowerCase().split(' ')

    return carMakes.filter(make => {
      if (!make.name) {
        return false
      }

      const optName = make.name.toLowerCase().split(' ') || []

      return optSearch.some(w => !!optName.find(n => n.startsWith(w)))
    })
  }, [carMakes, search])

  return { ...rest, carMakes, featuredMakes, filteredCarMakes }
}

export function useMakeDetail(makeId: Maybe<string>, opts?: QueryOpts) {
  const { data, ...rest } = useLovMakesQuery(
    { active: true },
    { enabled: !!makeId, ...opts }
  )

  const make = useMemo(() => {
    return data?.content.find(make => make.id === makeId)
  }, [makeId, data])

  return { ...rest, data, make }
}

export type LovMake = LovMakesResponse['content'][number]

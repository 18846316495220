import { operations } from 'driverama-core/api/driverama/generated/lov'
import { URLS } from 'driverama-core/constants/api'
import { QUERY_KEYS } from 'driverama-core/constants/queryKeys'
import { HTTPMethod } from 'driverama-core/constants/rest'
import { apiFetcher } from 'driverama-core/utils/fetcher'
import { Maybe } from 'driverama-core/utils/types'
import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { lovQueryOptions } from './lov.utils'

export type LovModelsSearchResponse = operations['getModels']['responses']['200']['content']['application/com.driverama-v1+json']
type QueryBody = operations['getModels']['requestBody']['content']['application/json']

type QueryOpts = UseQueryOptions<unknown, unknown, LovModelsSearchResponse>

export async function fetchLovModels(body: QueryBody, headers?: HeadersInit) {
  const res = await apiFetcher<LovModelsSearchResponse>(URLS.lovModelsSearch, {
    method: HTTPMethod.POST,
    body,
    headers
  })

  return res.json
}

export function getModelDetailQueryBody(modelId: Maybe<string>): QueryBody {
  return {
    filter: {
      ids: modelId ? [modelId] : [],
      yearFromIncludeNull: true,
      active: true
    }
  }
}

export function useLovModelsSearchQuery(
  body: QueryBody = {},
  opts?: QueryOpts
) {
  return useQuery(
    QUERY_KEYS.lovModelsSearch(body),
    async () => fetchLovModels(body),
    { ...lovQueryOptions, ...opts }
  )
}

interface ModelArgs {
  carMakeIds?: Maybe<string[]>
  ids?: string[]
}

export interface IMakeModelsMap {
  [makeId: string]: string[]
}

export interface IModelMakeMap {
  [modelId: string]: string
}

export function useModelList(args?: ModelArgs, enable = false) {
  const { data, ...rest } = useLovModelsSearchQuery(
    {
      filter: {
        ids: args?.ids ?? [],
        makeIds: args?.carMakeIds ?? [],
        yearFromIncludeNull: true,
        active: true
      }
    },
    { enabled: !!args?.carMakeIds || !!args?.ids || enable }
  )

  const models = useMemo(() => {
    return data?.content || []
  }, [data])

  const makeModelsMap = useMemo(() => {
    const map: IMakeModelsMap = {}
    models.forEach(model => {
      if (model.makeId) {
        map[model.makeId] = [model.id, ...(map[model.makeId] || [])]
      }
    })
    return map
  }, [models])

  const modelMakeMap: IModelMakeMap = useMemo(
    () =>
      Object.assign({}, ...models.map(model => ({ [model.id]: model.makeId }))),
    [models]
  )

  return { ...rest, models, makeModelsMap, modelMakeMap }
}

export function useModelDetail(modelId: Maybe<string>, opts?: QueryOpts) {
  const { data, ...rest } = useLovModelsSearchQuery(
    {
      filter: {
        ids: modelId ? [modelId] : [],
        yearFromIncludeNull: true,
        active: true
      }
    },
    { enabled: !!modelId, ...opts }
  )
  const model = useMemo(() => {
    return data?.content.find(model => model.id === modelId)
  }, [modelId, data])

  return { ...rest, data, model }
}

export type LovModel = LovModelsSearchResponse['content'][number]
